/* ID Style Variables */
@import '../colors';

$primary: #001A70;
$secondary: #f15b3f;
$light: #ddd;
$green: #3ca744;

$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg:                           #777;
$body-color:                        #222;

$jumbotron-bg:                      $fade-light;

//Process Page Styling
$process-page-themes: (
  "claim": $primary,
  "holder": $primary
) !default;

$state-theme-colors: (
  "green": $green
);

$theme-colors: map-merge($theme-colors, $state-theme-colors);

$btn-action-bg:                     $secondary;
$btn-pop-bg:                        $secondary;

$tooltip-icon-color:                $primary;

$navbar-bg:                         #C6912C;

$dropdown-bg:                       #C6912C;
$dropdown-hover-bg:                 $primary;
$dropdown-divider-bg:               #fff;

$dropdown-link-color:               $white;
$dropdown-link-hover-color:         $white;
$dropdown-link-hover-bg:            $primary;

$mobile-navbar-bg:                  #C6912C;
$static-page-header-color:          $primary;
$static-page-subtitle-color:        #333;

/* Image Size & Image Source Variables */
$banner-image-url:                  url('/images/banner.jpg');
$body-background-image-url:         url('/images/bg_texture.png');

$claim-header-logo-url:             url('/images/ID_process_logo.png');
$claim-header-logo-width:           145px;
$claim-header-logo-height:          80px;

$holder-header-logo-url:             $claim-header-logo-url;
$holder-header-logo-width:           $claim-header-logo-width;
$holder-header-logo-height:          $claim-header-logo-height;

$home-logo-url:                     url('/images/home_logo.png');
$home-logo-width:                   283px;
$home-logo-height:                  100px;
$home-logo-resize-value-map:        (width: 95px,
                                     height: $home-logo-height,
                                     background-image: $home-logo-url);

$alternate-home-logo-url:            $home-logo-url;
$alternate-home-logo-width:          $home-logo-width;
$alternate-home-logo-height:         $home-logo-height;
$alternate-home-logo-resize-value-map:      $home-logo-resize-value-map;

$treasurer-logo-url:                url('/images/treasurer.png');
$treasurer-logo-width:              115px;
$treasurer-logo-height:             110px;
$treasurer-logo-resize-value-map:   (width: $treasurer-logo-width,
                                     height: $treasurer-logo-height,
                                     background-image: $treasurer-logo-url);

$footer-logo-url:                   url('/images/footer_logo.png');
$footer-logo-width:                 190px;
$footer-logo-height:                181px;

$claimform-image-url:               url('/images/ID_claim_form_img.png');

$icon-email-url:                    none;
$icon-facebook-url:                 url('/images/icon_facebook.png');
$icon-instagram-url:                url('/images/icon_instagram.png');
$icon-twitter-url:                  url('/images/icon_twitter.png');
$icon-youtube-url:                  url('/images/icon_youtube.png');
$icon-linkedin-url:                 none;

$social-icon-width:                 25px;
$social-icon-height:                25px;

.home-page {

  #custom-btn {

    .img-overlay-container {
      position: relative;
      width: 100%;
    }

    .img-overlay-container:hover .img-overlay{
      opacity: 1;
    }

    .img-overlay-target {
      display: block;
      height: auto;
      width: 100%;
    }

    .img-overlay{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
    }
  }
}

.wg-default, .wg-default .country-selector {
  top: 0;
  left: 0;
  bottom: unset !important;
  right: unset !important;
}